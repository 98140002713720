import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import abi_master from "./../ABIs/abi-master.json"
import abi_distributor from "./../ABIs/abi-distributor.json"
import abi_consumer from "./../ABIs/abi-consumer.json"
import abi_mainnft from "./../ABIs/abi-mainnft.json"
import abi_mbdividends from "./../ABIs/abi-mbdividends.json"

import {
    CONTRACT_ADDRESS_MASTER,
    CONTRACT_ADDRESS_DISTRIBUTOR,
    CONTRACT_ADDRESS_CONSUMER,
    CONTRACT_ADDRESS_MAINNFT,
    CONTRACT_ADDRESS_MBDIVIDENDS,
} from '@config/addresses'

const Migrate = ({
    web3, walletAddress, connected
}) => {
    const [consumerHolders, setConsumerHolders] = useState([]);
    const [distributorHolders, setDistributorHolders] = useState([]);
    const [masterBrewerHolders, setMasterBrewerHolders] = useState([]);
    const [bonusItemHolders, setBonusItemHolders] = useState([]);
    const [blockNo, setBlockNo] = useState("");

    const [currentC, setCurrentC] = useState(0);
    const [currentD, setCurrentD] = useState(0);
    const [currentM, setCurrentM] = useState(0);
    const [pastC, setPastC] = useState(0);
    const [pastD, setPastD] = useState(0);
    const [pastM, setPastM] = useState(0);
    const [prevCurrentC, setPrevCurrentC] = useState(0);
    const [prevCurrentD, setPrevCurrentD] = useState(0);
    const [prevCurrentM, setPrevCurrentM] = useState(0);
    const [extraShares, setExtraShares] = useState(0);
    const [usdcval, setUsdcval] = useState(0);

    const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
    const BLOCK_DIFF_FOR_30_DAYS = Math.floor(3600 * 24 * 30 / 14);

    useEffect(() => {
        pollConnectReady();
    }, [connected]);


    const pollConnectReady = () => {
        if (connected) {
            refreshInfoForDividends();
        } else {
            setMasterBrewerHolders([]);
            setDistributorHolders([]);
            setConsumerHolders([]);
        }
    }

    const refreshTokens = async () => {
        setBlockNo("LOADING.......");
        setConsumerHolders([]);
        setDistributorHolders([]);
        setMasterBrewerHolders([]);
        setBonusItemHolders([]);

        if (!connected) {
            console.log("No web3");
            return;
        }

        await refreshHoldersInContract(abi_consumer, CONTRACT_ADDRESS_CONSUMER, setConsumerHolders);
        await refreshHoldersInContract(abi_distributor, CONTRACT_ADDRESS_DISTRIBUTOR, setDistributorHolders);
        await refreshHoldersInContract(abi_master, CONTRACT_ADDRESS_MASTER, setMasterBrewerHolders);

        setBlockNo(await web3.eth.getBlockNumber());
    }

    const refreshEvents = async () => {
        setBlockNo("LOADING.......");
        setConsumerHolders([]);
        setDistributorHolders([]);
        setMasterBrewerHolders([]);
        setBonusItemHolders([]);

        if (!connected) {
            console.log("No web3");
            return;
        }

        await refreshPackOwners(abi_mainnft, CONTRACT_ADDRESS_MAINNFT, setBonusItemHolders);

        setBlockNo(await web3.eth.getBlockNumber());
    }

    const giftNoToString = (giftNo) => {
        switch (parseInt(giftNo)) {
            case 0: return "No bonus";
            case 1: return "Luchador";
            case 2: return "Free pack";
            case 3: return "Distributor";
            case 4: return "Master Brewer";
            case 5: return "Brewmaster";
            case 6: return "PLS&TY";
            default: return "Error";
        }
    }

    const refreshPackOwners = async (abi, address, setFunc) => {
        if (!connected) {
            console.log("No web3");
            return;
        }

        if (await web3.eth.getCode(address) == "0x") {
            // no contract on this network
            console.log("No contract");
            return;
        }

        const holders = [];
        const contract = new web3.eth.Contract(abi, address);
        contract.events.PackOpened({}, { fromBlock: 0, toBlock: 'latest' }, (error, eventResult) => {
            if (error) {
                console.log('Error in PackOpened event handler: ' + error);
            } else {
                // console.log('PackOpened: ' + JSON.stringify(eventResult));

                if (eventResult.returnValues._giftNo > 0) {
                    let newHolder = {
                        owner: eventResult.returnValues._owner,
                        bonusItem: giftNoToString(eventResult.returnValues._giftNo),
                        packNo: parseInt(eventResult.returnValues._packNo) + 1,
                    };
                    holders.push(newHolder);
                }
            }
        });

        setFunc(holders);
    }

    const getNoTokensForTokenId = (tokenId) => {
        var tokens;
        if (tokenId >= 0 && tokenId <= 349) {
            // Starter Kit
            tokens = 500;
        } else if (tokenId >= 15000 && tokenId <= 15014) {
            // Supplier Kit
            tokens = 10000;
        } else if (tokenId >= 15300 && tokenId <= 15303) {
            // Master Kit
            tokens = 100000;
        } else if (tokenId >= 15300 && tokenId < 15360) {
            // Master Brewer
            tokens = 50000;
        } else if (tokenId >= 15000 && tokenId < 15300) {
            // Distributor
            tokens = 5000;
        } else if (tokenId >= 0 && tokenId < 15000) {
            // Consumer
            tokens = 250;
        } else {
            tokens = 0;
        }

        return tokens;
    }

    useEffect(() => {
        let sum = 0;

        for (let i = prevCurrentC; i < currentC; i++) {
            sum += getNoTokensForTokenId(i);
        }

        for (let i = prevCurrentD; i < currentD; i++) {
            sum += getNoTokensForTokenId(i);
        }

        for (let i = prevCurrentM; i < currentM; i++) {
            sum += getNoTokensForTokenId(i);
        }

        setExtraShares(sum);

    }, [currentC, currentD, currentM, prevCurrentC, prevCurrentD, prevCurrentM])

    const refreshInfoForDividends = async () => {
        if (!connected) {
            console.log("No web3");
            return;
        }

        if (await web3.eth.getCode(CONTRACT_ADDRESS_MAINNFT) == "0x") {
            // no contract on this network
            console.log("No contract");
            return;
        }

        const contract = new web3.eth.Contract(abi_mainnft, CONTRACT_ADDRESS_MAINNFT);

        const dividends = new web3.eth.Contract(abi_mbdividends, CONTRACT_ADDRESS_MBDIVIDENDS);

        const currentBlockNumber = await web3.eth.getBlockNumber();
        console.log("Block", currentBlockNumber);

        const mintIndexConsumers = await contract.methods._mintIndexConsumers().call();
        const mintIndexDistributors = await contract.methods._mintIndexDistributors().call();
        const mintIndexMasters = await contract.methods._mintIndexMasterBrewers().call();
        console.log(mintIndexConsumers, mintIndexDistributors, mintIndexMasters);

        setCurrentC(mintIndexConsumers);
        setCurrentD(mintIndexDistributors);
        setCurrentM(mintIndexMasters);

        setPastC(mintIndexConsumers);
        setPastD(mintIndexDistributors);
        setPastM(mintIndexMasters);

        const previousCurrentIndexes = await dividends.methods.getLastUpdate().call();
        setPrevCurrentC(previousCurrentIndexes[0]);
        setPrevCurrentD(previousCurrentIndexes[1]);
        setPrevCurrentM(previousCurrentIndexes[2]);

        contract.events.Transfer({}, { filter: { from: ZERO_ADDRESS }, fromBlock: 0, toBlock: 'latest' }, (error, eventResult) => {
            if (error) {
                console.log('Error in Transfer event handler: ' + error);
            } else {
                const tok = eventResult.returnValues.tokenId;
                const blockNumber = eventResult.blockNumber;

                if (blockNumber >= currentBlockNumber - BLOCK_DIFF_FOR_30_DAYS) {
                    // token was minted less than 30 days ago

                    if (tok >= 0 && tok < 15000) {
                        // consumer
                        setPastC((prev) => {
                            return Math.min(prev, tok);
                        });
                    } else if (tok >= 15000 && tok < 15300) {
                        // distributor
                        setPastD((prev) => {
                            return Math.min(prev, tok);
                        });
                    } else if (tok >= 15300 && tok < 15360) {
                        // free pack
                        setPastM((prev) => {
                            return Math.min(prev, tok);
                        });
                    }
                }
            }
        });

        console.log("finished");
    }

    const refreshHoldersInContract = async (abi, address, setFunc) => {
        if (!connected) {
            console.log("No web3");
            return;
        }

        if (await web3.eth.getCode(address) == "0x") {
            // no contract on this network
            console.log("No contract");
            return;
        }

        const contract = new web3.eth.Contract(abi, address);

        const numberOfTokens = await contract.methods.totalSupply().call();

        const tokenEvents = [];
        for (let i = 0; i < numberOfTokens; i++) {
            tokenEvents.push(getTokenHandler(contract, i))
        }
        const tokens = await Promise.all(tokenEvents);

        setFunc(tokens);
    }

    const getTokenHandler = async (contract, i) => {
        const tokenId = await contract.methods.tokenByIndex(i).call();
        const owner = await contract.methods.ownerOf(tokenId).call();

        return {
            tokenId: tokenId,
            owner: owner
        };
    }

    const formatCode = (consumers, distributors, masters, blockNo, bonuses) => {
        const consumerString = consumers.map((t) => `main.initialMigrateConsumerCard("${t.owner}", ${t.tokenId});`).reduce((p, c) => `${p}\n${c}`, "");
        const distributorString = distributors.map((t) => `main.initialMigrateDistributorCard("${t.owner}", ${t.tokenId});`).reduce((p, c) => `${p}\n${c}`, "");
        const masterString = masters.map((t) => `main.initialMigrateMasterBrewerCard("${t.owner}", ${t.tokenId});`).reduce((p, c) => `${p}\n${c}`, "");

        const bonusString = bonuses.map((t) => `Bonus NFT - ${t.bonusItem}: ${t.owner} in pack ${t.packNo}`).reduce((p, c) => `${p}\n${c}`, "");

        return `\n// Updated at block ${blockNo}\n${consumerString}\n${distributorString}\n${masterString}\n${bonusString}`;
    }

    const depositDividends = async () => {
        if (!connected) {
            console.log("No web3");
            return;
        }

        if (await web3.eth.getCode(CONTRACT_ADDRESS_MAINNFT) == "0x") {
            // no contract on this network
            console.log("No contract");
            return;
        }

        const dividends = new web3.eth.Contract(abi_mbdividends, CONTRACT_ADDRESS_MBDIVIDENDS);

        dividends.methods
            .depositFunds(usdcval, currentC, currentD, currentM, pastC, pastD, pastM, extraShares)
            .send({ from: walletAddress });
    }

    return (
        <div className="mt-16">
            <h2 className="text-4xl text-center uppercase outlineText md:text-5xl mb-10">Migrate information</h2>
            <button className="justify-center gap-2 items-center bg-green-400 hover:bg-green-300 text-sm text-black font-bold py-2 px-4 rounded-sm" onClick={() => refreshTokens()}>Refresh migration</button>
            <button className="justify-center gap-2 items-center bg-green-400 hover:bg-green-300 text-sm text-black font-bold py-2 px-4 rounded-sm ml-1" onClick={() => refreshEvents()}>Refresh events</button>
            <button className="justify-center gap-2 items-center bg-green-400 hover:bg-green-300 text-sm text-black font-bold py-2 px-4 rounded-sm ml-1" onClick={() => refreshInfoForDividends()}>Refresh info for dividends</button>
            <textarea style={{ width: 100 + '%', height: 500 + 'px', marginTop: 50 + 'px' }} value={formatCode(consumerHolders, distributorHolders, masterBrewerHolders, blockNo, bonusItemHolders)} readOnly></textarea>

            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="currentC">Current mint index CONSUMER</label>
                <input type="text" name="currentC" value={currentC} readOnly />
            </div>
            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="currentD">Current mint index DISTRIBUTOR</label>
                <input type="text" name="currentD" value={currentD} readOnly />
            </div>
            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="currentM">Current mint index MASTER BREWER</label>
                <input type="text" name="currentM" value={currentM} readOnly />
            </div>

            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="pastC">mint index 30 days ago CONSUMER</label>
                <input type="text" name="pastC" value={pastC} readOnly />
            </div>
            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="pastD">mint index 30 days ago DISTRIBUTOR</label>
                <input type="text" name="pastD" value={pastD} readOnly />
            </div>
            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="pastM">mint index 30 days ago MASTER BREWER</label>
                <input type="text" name="pastM" value={pastM} readOnly />
            </div>

            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="prevCurrentC">"Current mint index CONSUMER" at last deposit</label>
                <input type="text" name="prevCurrentC" value={prevCurrentC} readOnly />
            </div>
            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="prevCurrentD">"Current mint index DISTRIBUTOR" at last deposit</label>
                <input type="text" name="prevCurrentD" value={prevCurrentD} readOnly />
            </div>
            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="prevCurrentM">"Current mint index MASTER BREWER" at last deposit</label>
                <input type="text" name="prevCurrentM" value={prevCurrentM} readOnly />
            </div>

            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="extraShares">Number of new tokens since last deposit</label>
                <input type="text" name="extraShares" value={extraShares} readOnly />
            </div>

            <div className="mb-1">
                <label className="text-white mr-1" htmlFor="usdcval">Value to deposit in USDC (1,000,000 = $1)</label>
                <input type="text" name="usdcval" value={usdcval} onChange={(e) => setUsdcval(e.target.value)} />
            </div>

            <div className="mb-1">
                <button className="justify-center gap-2 items-center bg-green-400 hover:bg-green-300 text-sm text-black font-bold py-2 px-4 rounded-sm ml-1" onClick={() => depositDividends()}>DEPOSIT</button>
            </div>
        </div>
    )
}

const stateProps = (state) => ({
  web3: state.web3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(Migrate);